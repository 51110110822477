<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <p class="text-muted" style="margin-top: -14px;">Hello {{ accessName }}, Welcome Back!</p>

    <!-- <div class="row align-items-end mb-3 mb-lg-2">
      <div class="col">
        <h5 class="font-size-15 mb-3 mb-lg-0">Overview</h5>
      </div>

      <div class="col-md-auto ms-auto mb-0">
        <div class="form-check form-switch form-check-custom mb-0 me-lg-1">
          <input class="form-check-input mb-0" type="checkbox" id="liveUpdate" v-model="liveUpdate" @change="liveUpdates">

          <label class="form-check-label fw-normal mb-0" for="liveUpdate">Live Updates</label>
        </div>
      </div>
    </div> -->
    <div v-if="loading && !liveUpdateLoading">
      <div class="text-center">
        <div
          class="spinner-border text-primary my-3"
          v-if="loading"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div
    class=""
      v-if="
        account_type == 'admin'
      "
    >
      <div class="row mt-3">
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
            <div class="card shadow-none bg-primary overflow-hidden">
              <div class="card-body py-3">
                <div class="d-flex align-items-center">
                  <div class="w-100 animate__animated animate__fadeInLeft">

                    <p class="mb-1 font-size-16 text-white" >Total Sales (This Year)</p>
                    <div v-if="loading">
                        -
                    </div>
                    <h4 class="mb-1 mt-1 fw-medium text-white font-size-24" v-else>
                      {{ convertThousandMillion(admin_overview.total_sales_this_year)}}
                    </h4>
                  </div>
                  <div> 
                    <i class="mdi mdi-chart-bell-curve img-fluid animate__animated animate__fadeInRight display-6 text-info opacity-75"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
            <div class="card shadow-none bg-purple overflow-hidden">
              <div class="card-body py-3">
                <div class="d-flex align-items-center">
                  <div class="w-100 animate__animated animate__fadeInLeft">

                    <p class="mb-1 font-size-16 text-light" >Total Sales (This Month)</p>
                    <div v-if="loading">
                        -
                    </div>
                    <h4 class="mb-1 mt-1 fw-medium font-size-24" style="color: #b5fa8b;" v-else>
                      {{ convertThousandMillion(admin_overview.total_sales_this_month)}}
                    </h4>
                  </div>
                  <div> 
                    <i class="mdi mdi-chart-bell-curve-cumulative img-fluid animate__animated animate__fadeInRight display-6 text-info"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
            <div class="card shadow-none bg-info overflow-hidden">
              <div class="card-body py-3">
                <div class="d-flex align-items-center">
                  <div class="w-100 animate__animated animate__fadeInLeft">

                    <p class="mb-1 font-size-16 text-light">Total Merchant</p>
                    <div v-if="loading">
                        -
                    </div>
                    <h4 class="mb-1 mt-1 fw-medium font-size-24 light-green" v-else>
                        <span data-plugin="counterup">
                          <countTo :startVal="0" :endVal="parseFloat(admin_overview.total_merchant)" :duration="5000"></countTo>
                      </span>
                    </h4>
                  </div>
                  <div> 
                    <i class="bi bi-shop img-fluid animate__animated animate__fadeInRight display-6 light-green"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
            <div class="card bg-dark overflow-hidden">
              <div class="card-body py-3">
                <div class="d-flex align-items-center">
                  <div class="w-100 animate__animated animate__fadeInLeft">
                    <router-link :to="{name: 'outstanding balance'}">
                    <p class="mb-1 font-size-16 text-light">Outstanding Credit Sales</p>
                    <div v-if="loading">
                        -
                    </div>
                    <h4 class="mb-1 mt-1 fw-medium font-size-24 light-green"  v-else>
                      {{ convertThousandMillion(admin_overview.outstanding_credit_sales)}}
                    </h4>
                  </router-link>
                  </div>
                  <div> 
                    <i class="bi bi-cash-coin img-fluid animate__animated animate__fadeInRight display-6 light-green"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- end col-->
      </div>

      <div class="row mt-3 d-none">
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
            <div class="card shadow-none bg-dark overflow-hidden">
              <div class="card-body py-3">
                <div class="d-flex align-items-center">
                  <div class="w-100 animate__animated animate__fadeInLeft">

                    <p class="mb-1 font-size-16 text-white" >Total Sales (This Year)</p>
                    <div v-if="loading">
                        -
                    </div>
                    <h4 class="mb-1 mt-1 fw-medium light-green font-size-24" v-else>
                      {{ convertThousandMillion(admin_overview.total_sales_this_year)}}
                    </h4>
                  </div>
                  <div> 
                    <i class="mdi mdi-chart-bell-curve img-fluid animate__animated animate__fadeInRight display-6 text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
            <div class="card shadow-none bg-dark overflow-hidden">
              <div class="card-body py-3">
                <div class="d-flex align-items-center">
                  <div class="w-100 animate__animated animate__fadeInLeft">

                    <p class="mb-1 font-size-16 text-light" >Total Sales (This Month)</p>
                    <div v-if="loading">
                        -
                    </div>
                    <h4 class="mb-1 mt-1 fw-medium font-size-24 light-green" v-else>
                      {{ convertThousandMillion(admin_overview.total_sales_this_month)}}
                    </h4>
                  </div>
                  <div> 
                    <i class="mdi mdi-chart-bell-curve-cumulative img-fluid animate__animated animate__fadeInRight display-6 text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
            <div class="card shadow-none bg-dark overflow-hidden">
              <div class="card-body py-3">
                <div class="d-flex align-items-center">
                  <div class="w-100 animate__animated animate__fadeInLeft">

                    <p class="mb-1 font-size-16 text-light">Total Merchant</p>
                    <div v-if="loading">
                        -
                    </div>
                    <h4 class="mb-1 mt-1 fw-medium font-size-24 light-green" v-else>
                        <span data-plugin="counterup">
                          <countTo :startVal="0" :endVal="parseFloat(admin_overview.total_merchant)" :duration="5000"></countTo>
                      </span>
                    </h4>
                  </div>
                  <div> 
                    <i class="bi bi-shop img-fluid animate__animated animate__fadeInRight display-6 text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
            <div class="card bg-dark overflow-hidden">
              <div class="card-body py-3">
                <div class="d-flex align-items-center">
                  <div class="w-100 animate__animated animate__fadeInLeft">
                    <router-link :to="{name: 'outstanding balance'}">
                    <p class="mb-1 font-size-16 text-light">Outstanding Credit Sales</p>
                    <div v-if="loading">
                        -
                    </div>
                    <h4 class="mb-1 mt-1 fw-medium font-size-24 light-green"  v-else>
                      {{ convertThousandMillion(admin_overview.outstanding_credit_sales)}}
                    </h4>
                  </router-link>
                  </div>
                  <div> 
                    <i class="bi bi-cash-coin img-fluid animate__animated animate__fadeInRight display-6 text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- end col-->
      </div>

      <div class="row mb-3">
        <div class="col-lg-7">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-3 mb-md-0">
                <div class="me-2">
                  <i
                    class="bi bi-graph-up text-purple font-size-22"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    Sales Overview
                  </h4>
                </div>
                <div class="ms-auto">
                  <div
                    class="btn-group chart-btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <!-- <input
                      type="radio"
                      class="btn-check btn-check-info"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="1"
                      autocomplete="off"
                      value="1D"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="1"
                      >1D</label
                    > -->
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="2"
                      autocomplete="off"
                      value="7D"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-purple" for="2"
                      >7D</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="3"
                      autocomplete="off"
                      value="1M"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-purple" for="3"
                      >1M</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="4"
                      autocomplete="off"
                      value="1Y"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-purple" for="4"
                      >1Y</label
                    >
                  </div>
                  <!-- <select class="form-select pe-5" v-model="selectedStatisticsType" @change="updateStatistics();" :disabled="loading">
                    <option value="1D">1D</option>
                    <option value="7D">7D</option>
                    <option value="1M">1M</option>
                    <option value="1Y">1Y</option>
                  </select> -->
                </div>
              </div>
              <div>
                <div class="d-flex">
                  <div
                    v-for="(value, index) in StatisticsTotal"
                    :key="index"
                    class="me-3"
                  >
                    <div class="fw-normal text-muted font-size-14">
                      {{ value.label }}
                    </div>
                    <strong class="fw-medium" v-if="!loading">{{
                      convertThousandMillion(value.amount)
                    }}</strong>
                    <strong class="fw-medium" v-else>-</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body py-2 px-1 px-lg-3">
              <div class="text-center my-5"
                  v-if="loading && !liveUpdateLoading">
                <div
                  class="spinner-border text-primary my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading" style="min-height: 460px">
              </div>
              <div class="mt-1" v-else-if="!loading" style="min-height: 460px">
                <apexchart
                  type="line"
                  class="apex-charts"
                  dir="ltr"
                  height="465"
                  :options="salesData.chartOptions"
                  :series="salesData.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-3 mb-md-0">
                <div class="me-2">
                  <i
                    class="bi bi-shop text-purple font-size-22"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    Top Sellers
                  </h4>
                </div>
              </div>
            </div>
            <div class="card-body py-2 px-1 px-lg-3">
              <div class="text-center my-5"
                  v-if="loading && !liveUpdateLoading">
                <div
                  class="spinner-border text-primary my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading" style="min-height: 460px">
              </div>
              <div class="mt-0" v-else-if="!loading" style="min-height: 470px;" >
                <simplebar style="max-height: 470px" data-simplebar >
                <!-- datatable -->
                <div class="table-responsive mb-0">
                  <!-- striped -->
                      <b-table
                      class="text-nowrap align-middle mb-0 custom-datatable-table"
                      :items="admin_overview.top_sellers"
                      hover
                      :fields="visibleFields()"
                      responsive="sm"
                      :per-page="perPage2"
                      :current-page="currentPage2"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      :busy="loading"
                      show-empty
                      >        
                      <template>
                      <div class="text-center py-5 text-center text-muted" slot="table-busy">
                      <div
                          class="spinner-border text-secondary my-2"
                          role="status"
                          >
                          <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                      </div>
                      </template>
                      <template slot="empty">
                      <div class="text-center text-muted py-5">
                           No Data Available
                      </div>
                      </template>
                      <template slot="emptyfiltered">
                      <div class="text-center text-muted py-5">
                          <i class="bi bi-bank me-1"></i> There are no records matching your keyword
                      </div>
                      </template>

                      <template #cell(index)="data">
                      {{ currentPage2 * perPage2 + 1 - perPage2 + data.index }} 
                      </template>
                      <template #cell(company_name)="data">

                      <router-link class="text-purple fw-medium" :to="{ name: 'Edit Merchant', params: { id: data.item.account_db_code }}">
                          {{ data.item.company_name }}
                      </router-link
                  >
                     
                      </template>
                      <!-- <template #cell(index)="data">
                      {{ data.index + 1 }}
                      Whole Index
                      {{ currentPage2 * perPage2 - perPage2 + data.index }}
                      </template>

                      <template #cell(name)="data">
                      {{data.value}}
                      </template> -->
                      </b-table>
                </div>
                <!-- datatable end -->

              </simplebar>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- end row-->
      <div class="row mb-3 d-none">
        <div class="col-12">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-3 mb-md-0">
                <div class="me-2">
                  <i
                    class="uil uil-chart text-primary display-6"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    Project Payment Mode Overview
                  </h4>
                </div>
                <div class="ms-auto">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType2"
                      name="options2"
                      id="22"
                      autocomplete="off"
                      value="7D"
                      :disabled="loading2"
                      @change="getDashboard2()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="22"
                      >7D</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType2"
                      name="options2"
                      id="23"
                      autocomplete="off"
                      value="1M"
                      :disabled="loading2"
                      @change="getDashboard2()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="23"
                      >1M</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType2"
                      name="options2"
                      id="24"
                      autocomplete="off"
                      value="1Y"
                      :disabled="loading2"
                      @change="getDashboard2()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="24"
                      >1Y</label
                    >
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex">
                  <div
                    v-for="(value, index) in paymentModeTotal"
                    :key="index"
                    class="me-3"
                  >
                    <div class="fw-normal text-muted font-size-14">
                      {{ value.label }}
                    </div>
                    <strong class="fw-medium" v-if="!loading2">{{value.total
                    }}</strong>
                    <strong class="fw-medium" v-else>-</strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body py-2 px-1 px-lg-3">
              <div class="text-center my-5"
                  v-if="loading2 && !liveUpdateLoading">
                <div
                  class="spinner-border text-primary my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading2" style="min-height: 450px">
              </div>
              <div class="mt-1" v-else-if="!loading2" style="min-height: 450px">
                <apexchart
                  type="bar"
                  class="apex-charts"
                  dir="ltr"
                  height="450"
                  :options="paymentModeChart.chartOptions"
                  :series="paymentModeChart.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </div>
    <Lottie
      :path="'animate/loading-168.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
  </Layout>
</template>
<script>
import countTo from "vue-count-to";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import simplebar from "simplebar-vue";

export const equal2A = (value) => {
  return value !== "YOUR_API_ID";
};
export const equal2B = (value) => {
  return value !== "YOUR_API_HASH";
};

/**
 * Dashboard page
 */
const PageTitle = "Dashboard";
const CountryDefault = { name: "All Countries", value: "-", status: "" };

const CancelToken = axios.CancelToken;
let cancel;

export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    countTo,
    simplebar
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      accessName: "",
      showLottie: false,
      account_type: "",
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      submitted: false,
      loading: true,
      loading2: true,
      loading3: true,
      liveUpdateIntervalId: null,
      liveUpdate:false,
      liveUpdateLoading:false,
      buttonLoading: [false, false, false],
      currencySymbol: "MYR",
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      selectedPg: {},
      pgList: [],
      inactivePgList: [],
      contractDetails: [],
      transactionFee: "-",
      selectedStatisticsType: "1Y",
      selectedStatisticsType2: "1M",
      StatisticsTotal: [],
      paymentModeTotal: [],
      topStatistics: [],
      admin_overview:{
        total_sales_this_year:0,
        total_sales_this_month:0,
        total_merchant:0,
        outstanding_credit_sales:0,
        top_sellers:[]
      },
      top_settlement_applicant:[],
      top_applicant_current_limit:[],
      showAlerts:false,
      salesData: {
        series: [
          {
            name: "Desktops",
            data: [
              /* your data values */
            ],
          },
        ],
        chartOptions: {
          chart: {
            type: "line", // Add the chart type property
          },
          // Rest of your chart options
        },
      },
      paymentModeChart: {
        series: [
          {
            name: "",
            data: [
              /* your data values */
            ],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar", // Add the chart type property
          },
          // Rest of your chart options
        },
      },
      payinMerchants:[],
      payoutMerchants:[],
      returnData:[],
      totalData:"",
      //datatable
      currentPage2: 1,
      perPage2: 20,
      filter: "",
      filterOn: [],
      sortBy: "total_sales",
      sortDesc: true,
      fields: [
        {
          key: "index",
          label:"",
          thClass:"user-select-none",
          tdClass: "align-middle text-center",
          sortable: false,
        },
        {
          key: "company_name",
          label:"Company Name",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "total_sales",
          label:"Total Sales",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,true);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
        },
        {
          key: "total_orders",
          label:"Total Orders",
          thClass:"user-select-none",
          tdClass: "align-middle text-center",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,false);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          }
        },
        {
          key: "last_purchase_date",
          label:"Last Purchase Date",
          thClass:"user-select-none",
          tdClass: "align-middle text-center",
          sortable: false,
        }
      ],
      //datatable
    };
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A,
      },
      apiHash: {
        required,
        equal2B,
      },
    },
  },
  middleware: "authentication",
  async mounted() {
    await this.reload();
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    // this.getCountries();
    this.getDashboard();
    this.getDashboard2();
    // if (this.account_type == "merchant") {
    //   //this.getMerchantData();
    //   this.getDashboard();
    // }
  },
  created() {
    this.liveUpdate = false;
    this.liveUpdates()
  },
  beforeDestroy() {
    // Clear the interval when the component is about to be destroyed
    if (this.liveUpdateIntervalId) {
      clearInterval(this.liveUpdateIntervalId);
    }
  },
  methods: {
    visibleFields() {
      return this.fields.filter(field => {
      // Include all fields except the "Action" column if the condition is true
      return field.key !== 'action' || this.create_modify_permission;
    });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalData = filteredItems.length;
      this.currentPage2 = 1;
    },
    async getData(pages, keyword){
      if(cancel !== undefined) cancel();
      console.log("getData")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      this.disable_date = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("keyword", keyword);
      bodyFormData.append("sortBy", this.sortByData);
      // bodyFormData.append("dateStart", this.date_range[0]);
      // bodyFormData.append("dateEnd", this.date_range[1]);
      console.log(keyword)
      axios({
          method: "post",
          url: appConfig.APIHostAdmin + "",
          // method: "get",
          // url: "/api/bank_accounts.json",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          cancelToken: new CancelToken(c => cancel = c)
      })
      .then((response)=>{
          this.returnData = [];
          var resData = response.data;
          if (resData.status == 200) {
            this.totalData = resData.total;

            this.summary_data={
              today_in_transaction:resData.today_in_transaction || 0,
              today_in_volume:resData.today_in_volume || 0,
              total_in_volume:resData.total_in_volume || 0,
              total_all_bank_account_balance:resData.total_all_bank_account_balance || 0,
              today_out_transaction:resData.today_out_transaction || 0,
              today_out_volume:resData.today_out_volume || 0,
              total_out_volume:resData.total_out_volume || 0,
              total_active_bank_account:resData.total_active_bank_account || 0
            }
            
            var responseData = resData.data;
            //this.returnData = responseData;
            
            responseData.forEach((element,index) => {
              this.returnData.push(
                {
                  "account_db_code": element.account_db_code,
                  "id": element.id,
                  "currency": element.currency,
                  "type": element.type,
                  "bank_account_name": element.bank_account_name,
                  "bank_account_no": element.bank_account_no,
                  "bank_name": element.bank_name,
                  "ifsc_code": element.ifsc_code,
                  "today_in_transaction":element.today_in_transaction,
                  "total_in_amount": element.total_in_amount,
                  "today_in_amount": element.today_in_amount,
                  "today_out_transaction":element.today_out_transaction,
                  "total_out_amount": element.total_out_amount,
                  "today_out_amount": element.today_out_amount,
                  "record_last_bank_balance": element.current_bank_balance,
                  "current_bank_balance": element.current_bank_balance,
                  "transaction_limit": element.transaction_limit,
                  "remarks": element.remarks,
                  "upi_accounts": element.upi_accounts,
                  "created_at": element.created_at,
                  "active":element.active,
                  "custom_index":index
                }
              )
            });
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: this.$t("siteLang.oops"),
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: this.$t("siteLang.oops"),
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.disabled = false
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: this.$t("siteLang.oops"),
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    liveUpdates() {
      this.liveUpdateIntervalId = setInterval(() => {
        if (this.liveUpdate) {
          console.log('Updating');
          this.getDashboard();
          this.getDashboard2();
          this.liveUpdateLoading = true;
        }
      }, 10000);
    },
    
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    convertThousandMillion(value) {
      // Assuming this.transactionLimit is defined in your component's data
      return this.$refs.commonFunc
        .convertThousandMillion(parseInt(value))
        .replaceAll(".00", "");
    },
    updateStatistics() {
      console.log("updateStatistics");
      this.getDashboard();
      //this.getDashboard2();
    },
    async getDashboard() {
      console.log("getDashboard")
      this.$Progress.start();
      this.loading = true;
      // try {
      //   const result = await this.test();
      //   console.log(result); // You can do something with the result if needed
      // } catch (error) {
      //   console.error("Error in test function:", error);
      // } finally {
      //   console.log("end");
      // }
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("statisticsType", this.selectedStatisticsType);
      axios({
        method: "get",
        url:"api/dashboard.json",
        // method: "post",
        // url:  appConfig.APIHost + 'controller/admin/getStatisticData',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);

            this.admin_overview={
              total_sales_this_year:0,
              total_sales_this_month:0,
              total_merchant:0,
              outstanding_credit_sales:0,
              top_sellers:[]
            }

            this.admin_overview={
              total_sales_this_year: resData.data.total_sales_this_year,
              total_sales_this_month: resData.data.total_sales_this_month,
              total_merchant: resData.data.total_merchant,
              outstanding_credit_sales: resData.data.outstanding_credit_sales,
              top_sellers:resData.data.top_sellers
            }
            this.totalData = resData.data.top_sellers.length;

            var dateArray = [];
            var salesAmount = [];

            if (this.selectedStatisticsType == "7D") {
              // Define the date range and initialize the arrays
              const startDate = new Date();
              startDate.setDate(startDate.getDate() - 6); // 30 days ago

              // Generate random data for each day within the date range
              for (let i = 0; i < 7; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(currentDate.getDate() + i);

                // Format the date as 'dd MMM' (e.g., '01 Jun', '02 Jun', etc.)
                const formattedDate = currentDate.toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "short",
                });
                dateArray.push(formattedDate);

                // Generate random deposit amount and merchant fee
                const randomDeposit = Math.floor(
                  Math.random() * 100000
                ).toString();
                salesAmount.push(randomDeposit);
              }
             
            }
            if (this.selectedStatisticsType == "1M") {
              const today = new Date();
              const currentYear = today.getFullYear();
              const currentMonth = today.getMonth(); // Returns 0-based index (0 for January, 1 for February, etc.)
              const currentDay = today.getDate();
              const daysInMonth = new Date(
                currentYear,
                currentMonth + 1,
                0
              ).getDate();

              const startDate = new Date(currentYear, currentMonth, 1);
              const endDate = new Date(currentYear, currentMonth, currentDay);

              // Generate random data for each day up to today
              for (
                let currentDate = new Date(startDate);
                currentDate <= endDate;
                currentDate.setDate(currentDate.getDate() + 1)
              ) {
                // Format the date as 'dd MMM' (e.g., '01 Aug', '02 Aug', etc.)
                const formattedDate = currentDate.toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "short",
                });
                dateArray.push(formattedDate);

                // Generate random deposit amount and merchant fee
                const randomDeposit = Math.floor(
                  Math.random() * 100000
                ).toString();
                salesAmount.push(randomDeposit);
              }

              // Fill in the remaining days with zero values
              for (let i = currentDay + 1; i <= daysInMonth; i++) {
                //console.log(i + ' ' + parseInt(currentMonth + 1))
                const day = this.convertDate(
                  `${i} ${parseInt(currentMonth + 1)}`
                );

                dateArray.push(day);
                salesAmount.push("0");
              }
            }
            if (this.selectedStatisticsType == "1D") {
              // Generate random data for each day within the date range
              for (let i = 0; i < 24; i++) {
                dateArray.push(i + 1);

                const randomDeposit = Math.floor(
                  Math.random() * 100000
                ).toString();
                salesAmount.push(randomDeposit);
              }
            }
            if (this.selectedStatisticsType == "1Y") {
              const today = new Date();
              const currentMonth = today.getMonth();
              const currentYear = today.getFullYear();
              for (let i = 0; i < 12; i++) {
                const months = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];

                // Set deposit and merchant fee to 0 for months that are not the current month

                console.log(currentMonth);
                if (i < parseInt(currentMonth + 1)) {
                  dateArray.push(months[i] + " " + currentYear);
                  // Push deposit amount and merchant fee
                  // Generate random deposit amount and merchant fee
                  const randomDeposit = Math.floor(
                    Math.random() * 100000
                  ).toString();
                  salesAmount.push(randomDeposit);
                } else {
                  dateArray.push(months[i] + " " + currentYear);
                  salesAmount.push("0");
                }
              }
            }
            console.log(dateArray)
            this.salesData = {
              series: [
                {
                  name: "Total Sales",
                  data: salesAmount,
                }
              ],
              chartOptions: {
                chart: {
                  height: 450,
                  type: 'line',
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                colors: ["#239900", "#12b161"],
                stroke: {
                  width: [5, 2],
                  colors:  ["#239900", "#12b161"],
                  curve: "smooth",
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 3,
                    columnWidth: '85%',
                    //endingShape: 'rounded'
                  }
                },
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 0.7
                },
                title: {
                  text: "Sales Overview",
                  align: "left",
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: (y) => {
                      if (typeof y !== "undefined") {
                        return `${this.convertCurrencyFormat(y, false)}`;
                      }
                      return y;
                    },
                  },
                },
                grid: {
                  borderColor: "#f1f1f1",
                },
                labels: dateArray,
                markers: {
                  size: 3,
                  colors: "#ffffff",
                  strokeColors: ["#239900", "#462f94"],
                  strokeWidth: 1,
                  hover: {
                    size: undefined,
                    sizeOffset: 2,
                  },
                },
                yaxis: {
                  title: {
                    text: "Total Sales",
                  },
                  forceNiceScale:true,
                  min: 0,
                  labels: {
                      formatter: (y) =>  {
                        if (typeof y !== "undefined") {
                          return `${this.convertCurrencyFormat(parseFloat(y),false)}`;
                        }
                        return y;
                        },
                  },
                },
                legend: {
                  position: "top",
                  horizontalAlign: "right",
                  floating: true,
                  offsetY: -25,
                  offsetX: -5,
                },
              },
            };
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async getDashboard2() {
      console.log("getDashboard2")
      this.$Progress.start();
      this.loading2 = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("statisticsType", this.selectedStatisticsType2);
      axios({
        method: "post",
        url:  appConfig.APIHost + 'controller/admin/getPaymentModeData',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);

            // var date = [];
            // var totaleP = [];
            // var totalnonEP = [];
            // var countTotaleP = 0
            // var countTotalnonEP = 0
            
            // if (this.selectedStatisticsType2 == "7D") {
            //   // Define the date range and initialize the arrays
            //   const startDate = new Date();
            //   startDate.setDate(startDate.getDate() - 6); // 30 days ago

            //   // Generate random data for each day within the date range
            //   for (let i = 0; i < 7; i++) {
            //     const currentDate = new Date(startDate);
            //     currentDate.setDate(currentDate.getDate() + i);

            //     // Format the date as 'dd MMM' (e.g., '01 Jun', '02 Jun', etc.)
            //     const formattedDate = currentDate.toLocaleDateString("en-US", {
            //       day: "2-digit",
            //       month: "short",
            //     });
            //     date.push(formattedDate);

            //     // Generate random deposit amount and merchant fee
            //     const randomDeposit = Math.floor(
            //       Math.random() * 10
            //     ).toString();
            //     const profitPercentage = 80 / 100; // 2.5% merchant fee
            //     const randomFee = Math.floor(
            //       parseFloat(randomDeposit) * profitPercentage
            //     ).toString();
            //     totaleP.push(randomDeposit);
            //     var randomFeeMode = Math.random() < 0.2 ? 0 : randomFee;
            //     totalnonEP.push(randomFeeMode);
            //     countTotaleP = parseFloat(countTotaleP) + parseFloat(randomDeposit);
            //     countTotalnonEP =  parseFloat(countTotalnonEP) + parseFloat(randomFeeMode)
            //   }
             
            //   this.paymentModeTotal = [
            //     {
            //       label: "ePerolehan (eP)",
            //       total: countTotaleP,
            //     },
            //     {
            //       label: "Non-ePerolehan (Non-eP)",
            //       total: countTotalnonEP,
            //     },
            //   ];
            // }
            // if (this.selectedStatisticsType2 == "1M") {
            //   const today = new Date();
            //   const currentYear = today.getFullYear();
            //   const currentMonth = today.getMonth(); // Returns 0-based index (0 for January, 1 for February, etc.)
            //   const currentDay = today.getDate();
            //   const daysInMonth = new Date(
            //     currentYear,
            //     currentMonth + 1,
            //     0
            //   ).getDate();

            //   const startDate = new Date(currentYear, currentMonth, 1);
            //   const endDate = new Date(currentYear, currentMonth, currentDay);

            //   // Generate random data for each day up to today
            //   for (
            //     let currentDate = new Date(startDate);
            //     currentDate <= endDate;
            //     currentDate.setDate(currentDate.getDate() + 1)
            //   ) {
            //     // Format the date as 'dd MMM' (e.g., '01 Aug', '02 Aug', etc.)
            //     const formattedDate = currentDate.toLocaleDateString("en-US", {
            //       day: "2-digit",
            //       month: "short",
            //     });
            //     date.push(formattedDate);

            //     // Generate random deposit amount and merchant fee
            //     const randomDeposit = Math.floor(
            //       Math.random() * 100
            //     ).toString();
            //     const profitPercentage = 90 / 100; // 2.5% merchant fee
            //     const randomFee = Math.floor(
            //       parseFloat(randomDeposit) * profitPercentage
            //     ).toString();
            //     totaleP.push(randomDeposit);
            //     const randomFeeMode = Math.random() < 0.2 ? 0 : randomFee;
            //     totalnonEP.push(randomFeeMode);
            //     countTotaleP = parseFloat(countTotaleP) + parseFloat(randomDeposit);
            //     countTotalnonEP =  parseFloat(countTotalnonEP) + parseFloat(randomFeeMode)
            //   }

            //   // Fill in the remaining days with zero values
            //   for (let i = currentDay + 1; i <= daysInMonth; i++) {
            //     //console.log(i + ' ' + parseInt(currentMonth + 1))
            //     const day = this.convertDate(
            //       `${i} ${parseInt(currentMonth + 1)}`
            //     );

            //     date.push(day);
            //     totaleP.push("0");
            //     totalnonEP.push("0");
            //   }
            
            //   this.paymentModeTotal = [
            //     {
            //       label: "ePerolehan (eP)",
            //       total: countTotaleP,
            //     },
            //     {
            //       label: "Non-ePerolehan (Non-eP)",
            //       total: countTotalnonEP,
            //     },
            //   ];
            // }
            // if (this.selectedStatisticsType2 == "1Y") {
            //   const today = new Date();
            //   const currentMonth = today.getMonth();
            //   const currentYear = today.getFullYear();
            //   for (let i = 0; i < 12; i++) {
            //     const months = [
            //       "Jan",
            //       "Feb",
            //       "Mar",
            //       "Apr",
            //       "May",
            //       "Jun",
            //       "Jul",
            //       "Aug",
            //       "Sep",
            //       "Oct",
            //       "Nov",
            //       "Dec",
            //     ];

            //     // Set deposit and merchant fee to 0 for months that are not the current month

            //     console.log(currentMonth);
            //     if (i < parseInt(currentMonth + 1)) {
            //       date.push(months[i] + " " + currentYear);
            //       // Push deposit amount and merchant fee
            //       // Generate random deposit amount and merchant fee
            //       const randomDeposit = Math.floor(
            //         Math.random() * 1000
            //       ).toString();
            //       const profitPercentage = 85 / 100; // 2.5% merchant fee
            //       const randomFee = Math.floor(
            //         parseFloat(randomDeposit) * profitPercentage
            //       ).toString();
            //       totaleP.push(randomDeposit);
            //       const randomFeeMode = Math.random() < 0.2 ? 0 : randomFee;
            //       totalnonEP.push(randomFeeMode);
            //       countTotaleP = parseFloat(countTotaleP) + parseFloat(randomDeposit);
            //       countTotalnonEP =  parseFloat(countTotalnonEP) + parseFloat(randomFeeMode)
            //     } else {
            //       date.push(months[i] + " " + currentYear);
            //       totaleP.push("0");
            //       totalnonEP.push("0");
            //     }
            //   }
            //   this.paymentModeTotal = [
            //     {
            //       label: "ePerolehan (eP)",
            //       total: countTotaleP,
            //     },
            //     {
            //       label: "Non-ePerolehan (Non-eP)",
            //       total: countTotalnonEP,
            //     },
            //   ];
            // }

            const date = resData.data.date;
            const totaleP = resData.data.eP;
            const totalnonEP = resData.data.non_eP;
            const countTotaleP = resData.data.total_eP;
            const countTotalnonEP = resData.data.total_non_eP;
            
            this.paymentModeTotal = [
              {
                label: "ePerolehan (eP)",
                total: countTotaleP,
              },
              {
                label: "Non-ePerolehan (Non-eP)",
                total: countTotalnonEP,
              },
            ];

            this.paymentModeChart = {
              series: [
                {
                  name: "ePerolehan (eP)",
                  data: totaleP,
                },
                {
                  name: "Non-ePerolehan (Non-eP)",
                  data: totalnonEP,
                },
              ],
              chartOptions: {
                chart: {
                  height: 420,
                  type: 'bar',
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                colors: ["#274ba2", "#ff8700"],
                stroke: {
                  width: [2, 2],
                  colors:  ["#274ba2", "#ff8700"],
                  curve: "smooth",
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 3,
                    columnWidth: '85%',
                    //endingShape: 'rounded'
                  }
                },
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 0.7
                },
                title: {
                  text: "Statistical Overview",
                  align: "left",
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: (y) => {
                      if (typeof y !== "undefined") {
                        return `${this.convertCurrencyFormat(y, false)}`;
                      }
                      return y;
                    },
                  },
                },
                grid: {
                  borderColor: "#f1f1f1",
                },
                labels: date,
                markers: {
                  size: 3,
                  colors: "#ffffff",
                  strokeColors: ["#9e2988", "#462f94"],
                  strokeWidth: 1,
                  hover: {
                    size: undefined,
                    sizeOffset: 2,
                  },
                },
                yaxis: {
                  title: {
                    text: "Total of Payment Mode",
                  },
                  forceNiceScale:true,
                  min: 0,
                  labels: {
                      formatter: (y) =>  {
                        if (typeof y !== "undefined") {
                          return `${y}`;
                        }
                        return y;
                        },
                  },
                },
                legend: {
                  position: "top",
                  horizontalAlign: "right",
                  floating: true,
                  offsetY: -25,
                  offsetX: -5,
                },
              },
            };
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading2 = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading2 = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async test() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 5000);
      });
    },
    convertDate(dateStr) {
      try {
        const parts = dateStr.split(" ");
        const day = parts[0];
        const monthNumber = parseInt(parts[1]);

        if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
          throw new Error("Invalid month");
        }

        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthAbbreviation = months[monthNumber - 1];

        return day + " " + monthAbbreviation;
      } catch (error) {
        return "Invalid date format";
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          console.log(data);
          this.accessName = data.name;
          this.account_type = data.account_type;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<style scoped>
.light-green{
  color: #6be917;
}
.chart-btn-group label{margin-bottom: 0px;}
.b-table-empty-row td div{
  background-color: #fff !important;
}
.avatar-title i{font-size: 18px;}
</style>